export const cartConstants = {
  GET_CART_REQUEST: 'GET_CART_REQUEST',
  GET_CART_SUCCESS: 'GET_CART_SUCCESS',
  GET_CART_FAILURE: 'GET_CART_FAILURE',

  ADD_PRODUCT_REQUEST: 'ADD_PRODUCT_REQUEST',
  ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_FAILURE: 'ADD_PRODUCT_FAILURE',

  DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',

  CHECKOUT_CART_REQUEST: 'CHECKOUT_CART_REQUEST',
  CHECKOUT_CART_SUCCESS: 'CHECKOUT_CART_SUCCESS',
  CHECKOUT_CART_FAILURE: 'CHECKOUT_CART_FAILURE',

  CHECKOUT_COUPON_FAILURE: 'CHECKOUT_COUPON_FAILURE',

  GET_PAYMENT_GATEWAY_CONFISYS: 'GET_PAYMENT_GATEWAY_CONFISYS',
  SUCCESS_PAYMENT_GATEWAY_CONFISYS: 'SUCCESS_PAYMENT_GATEWAY_CONFISYS',
  ERROR_PAYMENT_GATEWAY_CONFISYS: 'ERROR_PAYMENT_GATEWAY_CONFISYS',

  CLEAR_CART: 'CLEAR_CART',

  CFS_PAYVALIDA_ENABLED_PAYMENTS: 'HABILITA_PAGOS_PAYVALIDA',
  CFS_PLACETOPAY_ENABLED_PAYMENTS: 'HABILITA_PAGOS_PLACETOPAY',

  CFS_ENABLED_VALUE: 'S',
  CFS_DISABLED_VALUE: 'N'
};
