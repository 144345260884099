import React, { useState, useEffect } from 'react';
import { Box, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useCart } from './CartContext'; // Asegúrate de tener este hook correctamente implementado
import { priceFormatter, formatMoney } from './../../helpers/formatMoney'; // Si tienes una función para formatear el dinero
import Response$ from '../../events/emitter';


const baseURL = process.env.REACT_APP_BASEURL;

const TableCart = ({ products, removeFromCart }) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const {
    porcentajeCoupon,
    setPorcentajeCoupon,
    loteries,
    setLoteries,
    numeroFraccionesMinimas,
    setNumeroFraccionesMinimas,
    numeroFraccionesMaximas,
    setNumeroFraccionesMaximas,
  } = useCart();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const PriceCell = ({ value }) => {
    if (value === undefined || value === null || isNaN(value)) {
      return <span>$ 0</span>;
    }
    return <span>$ {formatMoney(value, 0, 3, '.', ',')}</span>;
  };

  useEffect(() => {
    const handleEvent = (mensaje) => {
      setPorcentajeCoupon(mensaje.message.porcentaje_descuento || null);
      setLoteries(mensaje.loteries || []);
      setNumeroFraccionesMinimas(mensaje.message.numeroFraccionesMinimas || null);
      setNumeroFraccionesMaximas(mensaje.message.numeroFraccionesMaximas || null);
    };

    Response$.once('myEvent', handleEvent);

    return () => {
      // Response$.off('myEvent', handleEvent); // Si necesitas remover el listener
    };
  }, [setPorcentajeCoupon, setLoteries]);

  useEffect(() => {
    console.log("51-pocentajeCoupon", porcentajeCoupon);
    if (porcentajeCoupon !== null && loteries && products) {
      const discountMap = {};
      loteries.forEach((obj) => {
        discountMap[obj.idLoteria] = porcentajeCoupon;
      });

      const updatedProducts = products.map((product) => {
        const { ideLoteria, fracciones, discountApplied = false, total = 0 } = product;

        if (!discountApplied && fracciones >= numeroFraccionesMinimas && fracciones <= numeroFraccionesMaximas) {
          const discountPercentage = discountMap[ideLoteria] || 0;
          const valordescuento = discountPercentage ? total - (total * discountPercentage) / 100 : total;

          return {
            ...product,
            valordescuento,
            descuento: discountPercentage > 0 ? `${discountPercentage}%` : '0%',
            discountApplied: true,
          };
        } else if (!discountApplied) {
          return {
            ...product,
            valordescuento: total,
            descuento: '0%',
            discountApplied: true,
          };
        }
        return product;
      });

      setRows(updatedProducts);
    } else {
      setRows(products);
    }
  }, [porcentajeCoupon, loteries, products, numeroFraccionesMinimas, numeroFraccionesMaximas]);

  useEffect(() => {
    console.log('65-porcentajeCoupon', porcentajeCoupon);
    const baseColumns = [
      {
        field: 'abreviatura',
        headerName: 'Lotería',
        headerClassName: 'custom-header',
        width: 100,
        sortable: false,
        renderCell: (params) => (
          <img
            src={`${baseURL}/assets/images/loterias/${params.value}.png`}
            className="image-lotterie"
            alt="Lotería"
          />
        ),
      },
      {
        field: 'numero',
        headerName: 'Número',
        headerClassName: 'custom-header',
        width: 120,
        sortable: false,
        renderCell: (params) => (
          <div className="numberCart d-inline-block">
            {params.value.toString().split('').map((number, index) => (
              <span key={index}>{number}</span>
            ))}
          </div>
        ),
      },
      {
        field: 'serie',
        headerName: 'Serie',
        headerClassName: 'custom-header',
        width: 100,
        sortable: false,
        renderCell: (params) => (
          <div className="d-inline-block serieCart">
            <span>{params.value}</span>
          </div>
        ),
      },
      {
        field: 'fracciones',
        headerName: 'Fracciones',
        headerClassName: 'custom-header',
        sortable: false,
        width: 120,
        renderCell: (params) => <div className="d-inline-block fractionCart">{params.value}</div>,
      },
      {
        field: 'total',
        headerName: 'Valor',
        headerClassName: 'custom-header',
        width: 140,
        sortable: false,
        renderCell: (params) => (
          <div className="d-inline-block totalCart">
            <PriceCell value={params.value} />
          </div>
        ),
      },
      {
        field: 'actions',
        headerName: '',
        headerClassName: 'custom-header',
        width: 100,
        sortable: false,
        renderCell: (params) => (
          <button
            type="button"
            className="icon-button"
            title="Eliminar del Carrito de Compras"
            onClick={() => removeFromCart({ id: params.row.id }, 'lotteries')}
          >
            {params.row.deleting ? (
              <i className="fa fa-spinner fa-pulse" />
            ) : (
              <i className="flaticon-can" />
            )}
          </button>
        ),
      },
    ];

    if (porcentajeCoupon > 0) {
      console.log('146-porcentajeCoupon', porcentajeCoupon);
      baseColumns.splice(4, 0, {
        field: 'descuento',
        sortable: false,
        headerName: 'Descuento',
        headerClassName: 'custom-header',
        width: 120,
        renderCell: (params) => <div className="d-inline-block fractionCart">{params.value}</div>,
      });

      baseColumns.splice(6, 0, {
        field: 'valordescuento',
        headerName: 'Valor con Descuento',
        width: 210,
        sortable: false,
        headerClassName: 'custom-header',
        renderCell: (params) => (
          <div className="d-inline-block totalCart">
            <PriceCell value={params.value} />
          </div>
        ),
      });
    }

    setColumns(baseColumns);
  }, [porcentajeCoupon]);

  return (
    <section className="table-custom-data-cart table-cart table-cart-juegos">
      {!rows.length ? (
        <Alert className="my-2 content-center" severity="warning">
          No ha jugado ningún sorteo
        </Alert>
      ) : (
        <Box sx={{ width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            disableColumnSort
            disableColumnResize
            disableColumnMenu        
            getRowId={(row) => row.id}            
            rowHeight={100}
            disableExtendRowFullWidth={true}
          />
        </Box>
      )}
    </section>
  );
};

export default TableCart;
