import { orderConstants } from './../constants/OrderConstants';
import { OrderService } from './../services/OrderService';
import {decryptUserData} from '../services/security/informationCodingSecurity';
import { getCart } from './CartActions';
// Data para realizar peticion a egoi
import { trackEcommerceOrder } from './../shared/egoi';

export const checkStateOrder = (values) => (dispatch, getState) => {

  const checkStateOrderRequest = () => ({
    type: orderConstants.GET_ORDER_STATE_REQUEST
  });

  const checkStateOrderSuccess = payload => ({
    type: orderConstants.GET_ORDER_STATE_SUCCESS,
    payload
  });

  const checkStateOrderError = error => ({
    type: orderConstants.GET_ORDER_STATE_FAILURE,
    error
  });

  const user = getState().authentication.user;
  const obUser = decryptUserData(user);
  dispatch(checkStateOrderRequest());
  Promise.all([OrderService.getStateOrder(values), OrderService.getCartInfo(values.reference)]).then(response => {
    dispatch(getCart());
    response[0].carrito = response[1];
    response[0].nombre = (response[0].comprador && (response[0].comprador.hasOwnProperty('name') || response[0].comprador.hasOwnProperty('surname'))) ?
      `${response[0].comprador.name} ${response[0].comprador.surname}` :
      `${obUser.nombres} ${obUser.primerApellido} ${(obUser.segundoApellido)?obUser.segundoApellido:''}`;
    response[0].email = response[0].comprador ? response[0].comprador.email : obUser.email;
    response[0].documento = (response[0].comprador && response[0].comprador.hasOwnProperty('document')) ? response[0].comprador.document : obUser.usuario;
    response[0].celular = (response[0].comprador && response[0].comprador.hasOwnProperty('mobile')) ? response[0].comprador.mobile : obUser.celular;
    // VAlidacion del estado aprobado para enviar informacionde venta a egoi
    if ( response[0].estado=='APROBADO') {
      const iva=0;
      const shipping='';
      const discount=false;

      trackEcommerceOrder(response[0].carrito.id.toString(), response[0].monto.total, iva, shipping, discount);
    }
    dispatch(checkStateOrderSuccess(response[0]));
  }, reason => {
    reason.then(errorResponse => {
      const errorMessage = errorResponse.error;
      dispatch(checkStateOrderError(errorMessage.error.mensaje ? errorMessage.error.mensaje :
        'Ocurrió un error al momento de consultar el estado de la orden.'));
    });

  });

};
