/* eslint-disable no-empty */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Container, Alert, Button, Input, InputGroup, FormGroup, __esModule, Form, timeoutsShape } from 'reactstrap';
import { Link } from 'react-router-dom';
import { history } from '../../helpers/history.js';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';
import TableCart from './../../components/Cart/TableCart';
import TableReloads from './../../components/Cart/TableReloads';
import ModalAccept from './../../components/Modal/ModalAccept';
import ModalAge from '../../components/Modal/ModalAge';
import ModalComponent from './../../components/Modal/ModalComponent';
import ModalCuponesError from './../../components/Modal/ModalCuponesError.js';

//Helpers
import { priceFormatter, formatMoney } from './../../helpers/formatMoney';

//Actions
import { getCart, deleteProductCart, checkoutCart, checkoutCoupon } from './../../actions/CartActions';
import { toggleModalAccept } from './../../actions/ModalActions';
import { toggleModalAge } from '../../actions/ModalActions';
import { toggleModalLogin } from './../../actions/UserActions';
import { getTotalCart } from './../../reducers/cartReducer';
import { getPaymentConfisysEnabled } from '../../actions/CartActions';
import logoArrow from '../../assets/images/vector2.svg';
import Ellipse13 from '../../assets/images/Ellipse13.svg';
import line5 from '../../assets/images/Line5.svg';
import line6 from '../../assets/images/Line6.svg';
import EventEmitter from 'eventemitter3';
import Response$ from '../../events/emitter.js';
const { v1: uuidv1} = require('uuid');


// Data para realizar peticion a egoi
import { actualizarTotalCarrito,addBeat,initEgoi,trackEcommerceOrder } from '../../shared/egoi';


var msgError = '';

class CartContainer extends Component {


  constructor(props) {
    super(props);

    this.state = {
      product: null,
      type: null,
      valueDelete: true,
      messageAgeError: false,
      typePay:null,
      readonly: false,
      msgError: null,
      msgResp: null,
      actCoupon: null,
      activity: null,
      oldTotal: null,
      clean: null,
      numeroFraccionesMinimas : null,
      numeroFraccionesMaximas : null,
      openmodal:false
    };

    this.deleteProduct = this.deleteProduct.bind(this);
    this.setDeleteProduct = this.setDeleteProduct.bind(this);
    this.handleCheckoutCart = this.handleCheckoutCart.bind(this);
    this.goEditAge = this.goEditAge.bind(this);
    this.validateAge = this.validateAge.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }


  componentDidMount() {
    Response$.on('myEvent', (mensaje) => {
      typeof(mensaje.message) === 'string' ? ( this.state.msgError = mensaje.message, this.state.msgResp = null ): ( this.state.msgResp = mensaje.message, this.state.msgError = mensaje.description) ;
      this.state.actCoupon = mensaje.code == '200' || mensaje.code == '202' ? true : false ;
      setTimeout(() => {
        this.props.getCart();
      }, 3200);
      const loteriasIds = mensaje.loteries.map(lottery => lottery.idLoteria);

      this.props.items.lotteries;
      this.validateFractions(mensaje.message.numeroFraccionesMinimas,mensaje.message.numeroFraccionesMaximas,loteriasIds);
     
    });
    this.props.getCart();
    this.props.getPaymentGatewayConfisys();
    window.scrollTo(0, 0); 
  }

  componentWillUnmount() {
    this.state.actCoupon === true ? this.cleanCoupon() : null;
  }

  onSelectPay = (value) => {
    this.setState({ typePay: value });
  }
  handleCloseModal() {
    this.setState({ openmodal: false });
  }

  validateFractions(numeroFraccionesMinimas, numeroFraccionesMaximas, idLoteriaCupon) {
    const lotteries = this.props.items.lotteries;
  
    const idLoteriaList = Array.isArray(idLoteriaCupon) ? idLoteriaCupon : [idLoteriaCupon];
    // Filtramos las loterías que coinciden con el idLoteriaCupon
    const lotteriesWithId = lotteries.filter(lottery => idLoteriaList.includes(lottery.ideLoteria));
    // Recorremos las loterías que coinciden con el idLoteriaCupon
    for (let lottery of lotteriesWithId) {
      // Validamos si las fracciones de esa lotería están dentro del rango
      if (lottery.fracciones < numeroFraccionesMinimas || lottery.fracciones > numeroFraccionesMaximas) {

        this.setState({ openmodal: true });
        return false; // Si las fracciones están fuera del rango, mostramos el modal y retornamos false
      }
    }
  
    // Si todas las loterías con idLoteriaCupon están dentro del rango, no hacemos nada y retornamos true
    return true;
  }

  handleClick(e) {
    this.coupon(e);
  }

  cambiarReadOnly() {
    this.setState(prevState => ({ readonly: !prevState.readonly }));
  }

  cambiarError() {
    this.setState(prevState => ({ msgError: !prevState.msgError }));
  }

  cambiarResp() {
    this.setState(prevState => ({ msgResp: !prevState.msgResp }));
  }


  handleChange = (event) => {
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (alphanumericRegex.test(event.target.value)) {
      this.setState({value: event.target.value});
    } else {
      console.log('Error caracter no valido');
    }
  }


  SelectPayComponent = () => {
    const {user} = this.props;
    const selectMethod = this.state.typePay === 'PayValida' ? true : false;
    return (
      <div><Row type="flex" style={{justifyContent: 'space-evenly'}}>
        { this.props.paymentsEnabled && this.props.paymentsEnabled.HABILITA_PAGOS_PLACETOPAY === 'S' ?

          <div onClick={()=>this.onSelectPay('PlacetoPay')} className={this.state.typePay === 'PlacetoPay' ? ('selected-btn',this.state.actCoupon === true ? this.cleanCoupon() : null) : ''}
            id='place-to-pay'
            title='Placetopay'>
            <div className='saldo'>
              <div>Saldo</div>
              <div>{`$ ${formatMoney(user && user.saldo ? user.saldo : 0, 0, 3, '.', ',')}`}</div>
            </div>
          </div>
          : null}

        { this.props.paymentsEnabled && this.props.paymentsEnabled.HABILITA_PAGOS_PAYVALIDA === 'S' ?
          <div onClick={()=>this.onSelectPay('PayValida')} className={this.state.typePay === 'PayValida' ? 'selected-btn' : ''}
            id='payvalida'
            title='Payvalida'>
          </div>
          : null}
      </Row>
      <Row style={{ display: selectMethod ? 'block' : 'none' }}>
        {this.props.paymentsEnabled && this.props.paymentsEnabled.HABILITA_PAGOS_PAYVALIDA === 'S' ?
          <div className='cuponContainer'>
            <p className="cuponMsg" ><img class="input-positionImg line5" src={line5}></img><img class="input-positionImg ellipse" src={Ellipse13}></img><img class="input-positionImg line5" src={line6}></img></p>
            <p className="cuponMsg" >¿Tienes un cupón disponible?</p>
            <Form className='cuponform'>
              <Row className='cuponColInput'>
                <Col md={6} >
                  <FormGroup>
                    <div className='input-icons'>
                      <Input type="text" placeholder="Ingrésalo aquí" value={this.state.value||''} onChange={this.handleChange} className="cuponInput" maxlength='30' pattern="[A-Za-z0-9]+" readOnly={this.state.readonly}></Input>
                      <img class="input-positionImg input-icons" src={logoArrow}></img>
                    </div>
                  </FormGroup>
                </Col>
                <FormGroup><Col md={6}><Button className='cuponButton' onClick={e => this.handleClick(e)}>Aplicar {this.state.readonly && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}<span></span> </Button></Col></FormGroup>
              </Row>
              <div className="cuponAlert" style={{ display: this.state.msgError && this.state.value !== '' ? 'block' : 'none' }}>
                {this.state.msgError}
              </div><br></br>
            </Form>
          </div>
          : null}
      </Row></div>
    );
  }
  setDeleteProduct(product, type) {
    this.setState({ product, type });
    if (this.props.items.lotteries.length > 1) {
      this.setState({ valueDelete: false });
    };
    this.props.toggleModalAccept();
  }

  deleteProduct() {
    this.props.deleteProductCart(this.state.product, this.state.type, this.props.items, this.state.valueDelete);
  }


  validateAge() {
    if (JSON.parse(localStorage.getItem('userLoti')).fechaNacimiento === undefined) {
      return 'AgeUndefened';
    } else {
      const bornDate = new Date(JSON.parse(localStorage.getItem('userLoti')).fechaNacimiento);
      const today = new Date();
      if (today.getFullYear() - bornDate.getFullYear() < 18) {
        return 'ageInvalid';
      } else {
        return 'valid';
      }
    };
  };

  goEditAge() {
    if (this.validateAge()==='AgeUndefened') {
      if (history.location.pathname==='/mi-cuenta/1') {
        history.go();
      } else {
        history.push('mi-cuenta/1');
        history.go();
      }

    } else {
      this.props.toggleModalAge();
    }
  }
  // metodo que manda los parametro al util de egoi
  guardarOrdenPagar() {
    const iva=0;
    const shipping=0;
    const discount='';
    //initEgoi(this.props.user.email);
    this.props.items.lotteries.forEach((element) => {
      addBeat(element);
    });
    //trackEcommerceOrder(this.props.items.id.toString(), this.props.totalCart, iva, shipping, discount );
    actualizarTotalCarrito(this.props.totalCart);
  }

  async handleCheckoutCart () {
    if (!this.props.user) {
      this.props.toggleModalLogin('1');
    } else {
      if (this.validateAge() === 'valid') {
        let idTx = uuidv1();
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        this.state.typePay === 'PlacetoPay' && this.state.value === '' && this.state.actCoupon !== null ? await delay(3000) : null;


        const descriptionToPay = this.state.typePay === 'PlacetoPay' ? 'Compra Loticolombia WEB':'Compra Loticolombia WEB (PayValida)';
        const cartCheckout = {
          activity: 'noCoupon',
          idcart: this.props.items.id,
          token: this.props.user.token,
          ip: '104.196.96.174',
          aplicacion: 1,
          esRecargaGanaExpress: 0,
          numIdentificacion: this.props.user.usuario,
          typePay: this.state.typePay,
          numTransaccion: '1597582050786370',
          payment: {
            description: descriptionToPay,
            amount: {
              currency: 'COP',
              total: this.props.totalCart
            }
          },
          lotteries: this.props.items.lotteries.map(lottery => {
            return {
              id: lottery.id,
              name: lottery.name,
              precio: lottery.precio,
              descuento: lottery.descuento,  // Incluye el descuento aquí
            };
          })
        };
        //consumir servicios de egoi
        if (!this.state.actCoupon) {
          this.guardarOrdenPagar();
          this.props.checkoutCart(cartCheckout, idTx);
        } else {

          if (this.state.actCoupon) {
            cartCheckout.couponName = this.state.value;
            cartCheckout.activity = 'createCoupon';
            cartCheckout.totalSinDesc = this.state.oldTotal;
            cartCheckout.totalDescuento = this.state.oldTotal - this.props.totalCart;
            this.props.checkoutCart(cartCheckout, idTx);
          }
        }
      } else if (this.validateAge() === 'ageInvalid') {
        this.setState({ messageAgeError: true });
        this.props.toggleModalAge();
      } else {
        this.setState({ messageAgeError: false });
        this.props.toggleModalAge();
        return false;
      }
    }
  }



  async cleanCoupon() {
    if ( this.state.clean != true) {
      const descriptionToPay = this.state.typePay === 'PlacetoPay' ? 'Compra Loticolombia WEB':'Compra Loticolombia WEB (PayValida)';
      this.state.value = '';
      const cartCheckout = {
        activity: 'cosultCoupon',
        couponName: this.state.value,
        idcart: this.props.items.id,
        token: this.props.user.token,
        ip: '104.196.96.174',
        aplicacion: 1,
        numIdentificacion: this.props.user.usuario,
        typePay:this.state.typePay,
        numTransaccion: '1597582050786370',
        payment: {
          description: descriptionToPay,
          amount: {
            currency: 'COP',
            total: this.props.totalCart
          }
        }
      };
      this.props.checkoutCoupon(cartCheckout);
      this.state.clean = true;
    }
  }
  async handleloseModal() {

    this.setState({ openmodal: false });
  }

  async coupon(e) {
    const activityOk = !this.state.activity ? 'cosultCoupon' : 'createCoupon';
    if (this.state.oldTotal === null) {      
      this.state.oldTotal = this.props.totalCart;
    }


    if (!this.props.user) {
      this.props.toggleModalLogin('1');
    } else {
      if (this.validateAge() === 'valid') {
        this.cambiarReadOnly();

        const descriptionToPay = this.state.typePay === 'PlacetoPay' ? 'Compra Loticolombia WEB':'Compra Loticolombia WEB (PayValida)';

        const cartCheckout = {
          activity: 'cosultCoupon',
          couponName: this.state.value,
          idcart: this.props.items.id,
          token: this.props.user.token,
          ip: '104.196.96.174',
          aplicacion: 1,
          numIdentificacion: this.props.user.usuario,
          typePay:this.state.typePay,
          numTransaccion: '1597582050786370',
          payment: {
            description: descriptionToPay,
            amount: {
              currency: 'COP',
              total: this.props.totalCart
            }
          }

        };

        this.props.checkoutCoupon(cartCheckout);
        this.state.clean = false;

        setTimeout(() => {
          this.cambiarReadOnly();
        }, 3000);

        setTimeout(() => {
          this.cambiarError();
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }, 7000);

      } else if (this.validateAge() === 'ageInvalid') {
        this.setState({ messageAgeError: true });
        this.props.toggleModalAge();
      } else {
        this.setState({ messageAgeError: false });
        this.props.toggleModalAge();
        return false;
      }
    }
  }



  render() {
    const { loading, error, items, checkout, totalCart, user } = this.props;

    return (
      <Col xs={12} className="homeContainer">
        <ModalAccept actionAccept={this.deleteProduct}>
          <Alert color="warning" className="text-center mb-0">
            ¿Está seguro que desea eliminar el producto del carrito de compras?
          </Alert>
        </ModalAccept>

        <ModalCuponesError
          title='No es posible redimir el
          cupón en esta compra:'
          show={this.state.openmodal}
          onClose={this.handleCloseModal}
          onHandleCheckoutCart={this.handleCheckoutCart}
        />

        <ModalAge actionAge={this.goEditAge}>
          {this.state.messageAgeError===true &&
            <Alert color="warning" className="text-center mb-0">
              Debes ser mayor de edad para continuar esta transacción.
            </Alert>
          }
          {localStorage.getItem('userLoti') !== null &&
          !JSON.parse(localStorage.getItem('userLoti')).fechaNacimiento &&
            <Alert className="my-2 content-center" color="light">
              <span>Debes registrar tu edad antes de continuar. da click en aceptar para actualizar tus datos.</span>
            </Alert>
          }
        </ModalAge>

        <Row>
          <Container className="bg-primary return-seccion">
            <section className="mt-5 mb-4 p-4">
              <h3 className="first-title text-center my-5 interna-resultados">Carrito de Compras</h3>
              <Row className="justify-content-center">

                {loading && <CircularProgress />}

                {!loading && !error && items.lotteries.length > 0 &&
                  <Col xs={12} className="mb-4 p-0">
                    <h3 className="first-title text-left my-4 text-center"><span className="color-white">Loterías</span></h3>
                    <TableCart products={items.lotteries} removeFromCart={this.setDeleteProduct} />
                  </Col>
                }

                {!loading && !error && items.recharges.length > 0 &&
                  <Col xs={12} className="p-0 mb-5">
                    <h3 className="first-title text-left mb-4"><span className="color-white">Recargas</span></h3>
                    <TableReloads products={items.recharges} removeFromCart={this.setDeleteProduct} />
                  </Col>
                }

                {!loading && !error && !items.lotteries.length && !items.recharges.length &&
                  <Alert className="my-2 content-center" color="warning">
                    No ha añadido ningún producto al carrito de compras, lo invitamos a que juegue una &nbsp;
                    <Link to={localStorage.getItem('userLoti') !== null ? JSON.parse(localStorage.getItem('userLoti')).actualizarusuario=== 'N' ? '/loterias' : '/mi-cuenta/1' : '/loterias'}>lotería</Link>
                    {/*o a que haga una <Link to="/recargas">recarga</Link>*/}
                  </Alert>
                }

                {!loading && !error && items.pendiente &&
                <Alert className="my-2 content-center" color="warning">
                  Recuerde que aún tiene una transacción pendiente. Línea gratuita de servicio al cliente 01 8000 184262 ó en Medellin Tel (4) 444 44 41.
                </Alert>}


                {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}

                <div className='p-5'> 
                  <h3 className="first-title text-center my-4 w-100"><span className="color-white">Total a Pagar: <strong>{`$ ${formatMoney(totalCart, 0, 3, '.', ',')}`}</strong></span></h3> 
                </div>

                {(items.lotteries.length > 0 || items.recharges.length > 0) &&
                  <Col xs={12}>
                    {this.SelectPayComponent()}
                    <Row className="justify-content-center">
                      <div>
                        <button
                          type="button"
                          className="button-rounded button-rounded-gradient-resultados px-30 py5"
                          onClick={this.handleCheckoutCart}
                          disabled={this.state.typePay == null}
                        >
                          <div className="d-flex justify-content-between align-items-center cuponPagar">
                            {this.state.typePay != null && <span>Proceder a Pagar</span>}
                            {this.state.typePay == null && <span>*Seleccione m&eacute;todo de pago</span>}
                            <span>
                              {!checkout.loading && <i className="flaticon-commerce d-inline-block vertical-middle ml-2" />}
                              {checkout.loading && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block vertical-middle" />}
                            </span>
                          </div>
                        </button>
                      </div>
                      {checkout.error && <Col xs={12}><Alert className="my-2 content-center" color="danger"> {checkout.error} </Alert></Col>}
                      {checkout.success &&
                        <ModalComponent className="modal-dialog-centered" dontClose>
                          <CircularProgress message="Redireccionando a la Pasarela de Pago" />
                        </ModalComponent>
                      }
                    </Row>
                  </Col>
                }
              </Row>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}





const mapStateToProps = (state) =>{
  const { loading, error, items, checkout, paymentsEnabled} = state.cart;
  return {
    loading,
    error,
    items,
    checkout,
    user: state.authentication.user,
    totalCart: getTotalCart(state),
    paymentsEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCart() {
      dispatch(getCart());
    },
    deleteProductCart(idProduct, typeProduct, items, valueDelete) {
      dispatch(toggleModalAccept());
      dispatch(deleteProductCart(idProduct, typeProduct, items, valueDelete));
    },
    toggleModalAccept() {
      dispatch(toggleModalAccept());
    },
    toggleModalAge() {
      dispatch(toggleModalAge());
    },
    /*checkoutCart(values) {
      dispatch(checkoutCart(values));
    },*/
    checkoutCart(values, idTx) {
      dispatch(checkoutCart(values, idTx));
    },
    toggleModalLogin(tab) {
      dispatch(toggleModalLogin(tab));
    },
    getPaymentGatewayConfisys() {
      dispatch(getPaymentConfisysEnabled());
    },
    checkoutCoupon(values) {
      dispatch(checkoutCoupon(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);

