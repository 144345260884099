import { webConfisyConstants } from '../constants/WebConfisysConstants';
import WebConfisysService from '../services/webConfisysService';

//====================================================================
// Loading web confisys
//====================================================================

export const loadwebConfisys = () => (dispatch, getState) => {

  const webConfisysRequest = () => ({
    type: webConfisyConstants.LIST_CONFISYS_REQUEST
  });

  const webConfisysSuccess = payload => ({
    type: webConfisyConstants.LIST_CONFISYS_SUCCESS,
    payload
  });

  const webConfisysError = error => ({
    type: webConfisyConstants.LIST_CONFISYS_FAILURE,
    error
  });

  dispatch(webConfisysRequest());
  const nombreConfisys = process.env.REACT_APP_NOMBRE_CONFISYS;
  WebConfisysService.loadListWebConfisys(nombreConfisys)
    .then(
      listWebConfisys => {
        if (listWebConfisys.data && listWebConfisys.data.length) {
          listWebConfisys.data.forEach(confisys => {
            confisys.nombre = nombreConfisys;
          });
        }
        dispatch(webConfisysSuccess(listWebConfisys.data));
      },
      error => {
        dispatch(webConfisysError(error.message ? error.message : 'Ocurrió un error al momento de traer el listado de premios'));
      }
    );
};
