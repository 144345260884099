import { gameConstants } from './../constants/GameConstants';
import GameService from './../services/GameService';
import {encrypt} from '../services/security/encryptorHandlerSecurity';
import numberFavoriteSeries$ from './../events/emitter';
import newNumberMajor$ from './../events/emitter';


//====================================================================
// Set lottery to play
//====================================================================

export const setLotteryToPlay = (lottery) => (dispatch) => {
  const fractions = [];
  for (let index = 0; index < lottery.numFracciones; index++) {
    fractions.push({
      label: '1',
      value: 1,
      disabled: false
    });
  }

  localStorage.setItem('gameLoti', encrypt(JSON.stringify(lottery)));

  dispatch({
    type: gameConstants.SET_LOTTERY,
    payload: lottery
  });

  dispatch({
    type: gameConstants.SET_GAME_FRACTIONS,
    payload: fractions
  });
};

//====================================================================
// Set disabled fractions
//====================================================================

export const setDisabledFractions = (validFractions) => (dispatch) => {
  dispatch({
    type: gameConstants.DISABLED_GAME_FRACTION,
    payload: validFractions
  });
};

//====================================================================
// Set enable fractions
//====================================================================

export const setEnabledFractions = (validFractions) => (dispatch) => {
  dispatch({
    type: gameConstants.ENABLED_GAME_FRACTION,
    payload: validFractions
  });
};

//====================================================================
// Loading All List Series
//====================================================================

export const loadListSeries = (values) => (dispatch) => {

  const listSeriesRequest = () => ({
    type: gameConstants.GAME_SERIES_REQUEST
  });

  const listSeriesSuccess = payload => ({
    type: gameConstants.GAME_SERIES_SUCCESS,
    payload
  });

  const listSeriesError = error => ({
    type: gameConstants.GAME_SERIES_FAILURE,
    error
  });

  dispatch(listSeriesRequest());
  GameService.loadListSeries(values)
    .then(
      series => {
        if (Array.isArray(series.data)) {
          series.data = series.data.slice(0, 10);
          series.data.forEach(serie => {
            serie.label = serie.serie;
            serie.value = serie.serie;
          });
          series.data.push({edited: true});
          dispatch(listSeriesSuccess(series.data));
        } else {
          dispatch(listSeriesError('Actualmente no existe fracciones disponibles para el número seleccionado'));
        }
      },
      error => {
        dispatch(listSeriesError(error.message ? error.message : 'Ocurrió un error al momento de consultar las series'));
      }
    );
};

export const clearListSeries = () => (dispatch) => {

  const listSeriesRequest = () => ({
    type: gameConstants.GAME_SERIES_REQUEST
  });

  const listSeriesSuccess = payload => ({
    type: gameConstants.GAME_SERIES_SUCCESS,
    payload
  });

  dispatch(listSeriesRequest());
  dispatch(listSeriesSuccess([]));
};

//====================================================================
// Loading Single Serie
//====================================================================

export const loadSingleSerie = (values) => (dispatch) => {

  const serieRequest = () => ({
    type: gameConstants.GET_SERIE_REQUEST
  });

  const serieSuccess = payload => ({
    type: gameConstants.GET_SERIE_SUCCESS,
    payload
  });

  const serieError = error => ({
    type: gameConstants.GET_SERIE_FAILURE,
    error
  });

  dispatch(serieRequest());
  GameService.loadListSeries(values)
    .then(
      series => {
        if (Array.isArray(series.data)) {
          dispatch(serieSuccess(series.data[0]));
        } else {
          dispatch(serieError('La serie ingresada no se encuentra disponible'));
        }
      },
      error => {
        dispatch(serieError(error.message ? error.message : 'Ocurrió un error al momento de consultar las series'));
      }
    );
};

//====================================================================
// Loading alternate number
//====================================================================
export const loadListNumbers = (values) => (dispatch) => {

  const listSeriesRequest = () => ({
    type: gameConstants.GAME_SERIES_REQUEST
  });

  const listSeriesSuccess = payload => ({
    type: gameConstants.GAME_SERIES_SUCCESS,
    payload
  });

  const listSeriesError = error => ({
    type: gameConstants.GAME_SERIES_FAILURE,
    error
  });

  GameService.loadListNumbers(values.numero)
    .then(
      numeros => {
        //servicio para llamar el mismo numero requerido pero con otroas loterias
        numberFavoriteSeries$.emit('myEventSerie', numeros);
      },
      error => {
        dispatch(listSeriesError(error.message ? error.message : 'error  consumir el servicio de numero jugar'));
      }
    );
};

//====================================================================
// Loading alternate number
//====================================================================
export const loadListNumbersLargestSmallest = (values) => (dispatch) => {

  const listSeriesError = error => ({
    type: gameConstants.GAME_SERIES_FAILURE,
    error
  });

  GameService.loadListNumbersLargestSmallest(values)
    .then(
      numeros => {
        //servicio para llamar el mismo numero requerido pero con otroas loterias
        newNumberMajor$.emit('myEventMajor', numeros);
      },
      error => {
        dispatch(listSeriesError(error.message ? error.message : 'Error de servicio'));
      }
    );
};
