import { cartConstants } from './../constants/CartConstants';
import CartService from './../services/CartService';
import { toggleModalGeneral } from './ModalActions';
import {reset} from 'redux-form';
import { v1 as uuidv1 } from 'uuid';
import { gameConstants } from '../constants/GameConstants';
import { isObjectNotEmpty } from '../utils/objectUtils';
import { connect } from 'react-redux';
import EventEmitter from 'eventemitter3';
//import Response$ from './../containers/Cart/CartContainer';
import Response$ from './../events/emitter';


export const getCart = (combine) => (dispatch) => {


  const cartRequest = () => ({
    type: cartConstants.GET_CART_REQUEST
  });

  const cartSuccess = payload => ({
    type: cartConstants.GET_CART_SUCCESS,
    payload
  });

  const cartError = error => ({
    type: cartConstants.GET_CART_FAILURE,
    error
  });

  dispatch(cartRequest());
  CartService.getCart(combine)
    .then(
      cart => {
        if (combine) {
          CartService.saveCart(cart.data, false)
            .then(
              cart => {
                dispatch(cartSuccess(cart.data));
              },
              error => {
                dispatch(cartError(error.message ? error.message : 'Ocurrió un error al momento de guardar el carrito de compras'));
              }
            );

        } else {
          dispatch(cartSuccess(cart.data));
        }
      },
      error => {
        dispatch(cartError(error.message ? error.message : 'Ocurrió un error al momento de traer el carrito de compras'));
      }
    );
};

export const addProductCart = (values, typeProduct, formAdded, redirect) => (dispatch, getState) => {

  const cartAddRequest = () => ({
    type: cartConstants.ADD_PRODUCT_REQUEST
  });

  const cartAddSuccess = products => ({
    type: cartConstants.ADD_PRODUCT_SUCCESS,
    payload: { products, typeProduct }
  });

  const cartAddError = error => ({
    type: cartConstants.ADD_PRODUCT_FAILURE,
    error
  });

  dispatch(cartAddRequest());
  values.id = uuidv1();

  const items = getState().cart.items;
  const cartJson = JSON.parse(JSON.stringify(items));
  cartJson[typeProduct].push(values);

  CartService.saveCart(cartJson, false)
    .then(
      cart => {
        dispatch(cartAddSuccess(values));
        dispatch(reset(formAdded));
        if (formAdded === 'GameForm') {
          dispatch({type: gameConstants.RESET_GAME});
        }
        if (!redirect) {
          dispatch(toggleModalGeneral());
        }
      },
      error => {
        dispatch(cartAddError(error.message ? error.message : 'Ocurrió un error al momento de guardar el carrito de compras'));
      }
    );
};


export const deleteProductCart = (values, typeProductDelete, items, valueDelete) => (dispatch) => {

  const cartDeleteRequest = () => ({
    type: cartConstants.DELETE_PRODUCT_REQUEST,
    payload: {idProductDelete: values.id, typeProductDelete}
  });

  const cartDeleteSuccess = payload => ({
    type: cartConstants.DELETE_PRODUCT_SUCCESS,
    payload: {idProductDelete: values.id, typeProductDelete}
  });

  const cartDeleteError = errorDelete => ({
    type: cartConstants.DELETE_PRODUCT_FAILURE,
    payload: {idProductDelete: values.id, typeProductDelete, errorDelete}
  });

  dispatch(cartDeleteRequest());

  const cartJson = JSON.parse(JSON.stringify(items));

  if (cartJson[typeProductDelete].length > 1) {
    cartJson[typeProductDelete] = cartJson[typeProductDelete].filter(product => product.id !== values.id);
  }

  CartService.saveCart(cartJson, valueDelete)
    .then(
      cart => {
        dispatch(cartDeleteSuccess(values));
      },
      error => {
        dispatch(cartDeleteError(error.message ? error.message : 'Ocurrió un error al momento de eliminar el producto'));
      }
    );
};


export const checkoutCart = (values, idTx) => (dispatch) => {

  const checkoutCartRequest = () => ({
    type: cartConstants.CHECKOUT_CART_REQUEST
  });

  const checkoutCartSuccess = payload => ({
    type: cartConstants.CHECKOUT_CART_SUCCESS,
    payload
  });

  const checkoutCartError = error => ({
    type: cartConstants.CHECKOUT_CART_FAILURE,
    error
  });

  dispatch(checkoutCartRequest());
  CartService.checkoutCart(values, idTx)
    .then(
      response => {
        const data = response.data;
        if (typeof data == 'string' && data.substring(0, 7) == 'Error: ') {
          dispatch(checkoutCartError(data.substring(7)));
        } else if (typeof data == 'object' && data.statusCode && data.statusCode != 200) {
          dispatch(checkoutCartError(`${data.response.statusCode} - ${data.response.body.status.message}`));
        } else if (isObjectNotEmpty(data) && data.hasOwnProperty('error') ) { 
          dispatch(checkoutCartError(data.message));
        } else {
          dispatch(checkoutCartSuccess(data));
          dispatch(toggleModalGeneral());
          setTimeout(() => {
            if ( data.CODE != undefined ) {
              window.location = 'https://' + data.DATA.checkout;
            } else if (data.processUrl) {
              window.location = response.data.processUrl;
            }
          }, 1000);
        }
      },
      error => {
        error.then(errorResponse => {
          const errorMessage = errorResponse.error ? errorResponse.error : { error : { mensaje: '' } };
          dispatch(checkoutCartError(errorMessage.error.mensaje ? errorMessage.error.mensaje :
            'Ocurrió un error al momento de procesar el carrito de compras.'));
        });
      }
    );
};


export const getPaymentConfisysEnabled = () => (dispatch) => {

  const requestPaymentConfisysEnabled = () => ({
    type: cartConstants.GET_PAYMENT_GATEWAY_CONFISYS
  });

  const successPaymentConfisysEnabled = (payload) => ({
    type: cartConstants.SUCCESS_PAYMENT_GATEWAY_CONFISYS,
    payload
  });

  const errorPaymentConfisysEnabled = (error) => ({
    type: cartConstants.ERROR_PAYMENT_GATEWAY_CONFISYS,
    error
  });

  dispatch(requestPaymentConfisysEnabled);
  CartService.obtenerConfisysPagos().then(
    (cfs) => {
      cfs.data.map((item, key) => {
        item.valorStr = (item.valorStr === '' ? cartConstants.CFS_ENABLED_VALUE: item.valorStr);
        return item;
      });
      console.log(cfs);
      dispatch(successPaymentConfisysEnabled(cfs));
    },
    (error) => {
      dispatch(errorPaymentConfisysEnabled(error));
    }
  );
};


export const checkoutCoupon = (values) => (dispatch) => {
  const errorCheckoutCoupon = (error) => ({
    type: cartConstants.CHECKOUT_COUPON_FAILURE,
    error
  });

  CartService.checkoutCoupon(values)
    .then(response => {
      const data = response.req;
      Response$.emit('myEvent', data);
    })
    .catch(error => {
      dispatch(errorCheckoutCoupon(error));
    });
};


