// ConteoRegresivoActions.js

import { conteoRegresivoConstants } from '../constants/ConteoRegresivoConstants.js';
import ConteoRegresivoService from './../services/ConteoRegresivoService';

export const loadPrimerActivo = () => (dispatch) => {
  const primerActivoRequest = () => ({
    type: conteoRegresivoConstants.PRIMER_ACTIVO_REQUEST
  });

  const primerActivoSuccess = payload => ({
    type: conteoRegresivoConstants.PRIMER_ACTIVO_SUCCESS,
    payload
  });

  const primerActivoError = error => ({
    type: conteoRegresivoConstants.PRIMER_ACTIVO_FAILURE,
    error
  });

  dispatch(primerActivoRequest());

  return ConteoRegresivoService.consultarPrimerActivo()
    .then(
      response => {
        dispatch(primerActivoSuccess(response)); 
        return response; 
      },
      error => {
        dispatch(primerActivoError(error.message ? error.message : 'Ocurrió un error al momento de traer el conteo regresivo'));
        throw error; 
      }
    );
};
